import React from "react";

import { SlickSlider, SliderItem } from "reusecore/SlickSlider";
// import Banner from "sections/Banner";
import BannerTwo from "sections/BannerTwo";
import BannerThree from "sections/BannerThree";

import BannerSliderWrapper from "./bannerSlider.style";
import BannerOne from "../BannerOne";
import BannerFour from "../BannerFour";

const BannerSlider = () => {
  const settings = {
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    mobileFirst: true,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // customPaging: (i) => <div style={{width: '10px', borderRadius: '50%', backgroundColor: 'white'}}></div>,
  };
  return (
    <BannerSliderWrapper id="top"> 
        <SlickSlider {...settings}>
          <SliderItem>
            <BannerOne />
          </SliderItem>
          <SliderItem>
            <BannerTwo />
          </SliderItem>
          <SliderItem>
            <BannerThree />
          </SliderItem>
          <SliderItem>
            <BannerFour />
          </SliderItem>
        </SlickSlider> 
    </BannerSliderWrapper>
  );
};

export default BannerSlider;
