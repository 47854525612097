import React, { useState } from "react"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"
// import Image from "reusecore/Image";
import Input from "reusecore/Form/Input"
import Button from "reusecore/Button"

import BannerImage from "assets/images/banners/banner-three/cryptik-banner-three-thumb.svg"
import particleTopLeft from "assets/images/particles/banner/particle-top-left.png"
import particleUnderLogo from "assets/images/particles/banner/particle-under-logo.png"
import prticleTopRight from "assets/images/particles/banner/prticle-top-right.png"
import particleBottomLeft from "assets/images/particles/banner/particle-bottom-left.png"
import particleBottomRight from "assets/images/particles/banner/particle-bottom-right.png"

// import { FaGithub } from 'react-icons/fa'
import { FaSearch, FaCertificate, FaBezierCurve } from 'react-icons/fa';

// import keyIcon from 'assets/images/banners/banner-two/cryptik-banner-key-icon.svg';
// import watchIcon from 'assets/images/banners/banner-two/cryptik-banner-watch-icon.svg';
// import heartIcon from 'assets/images/banners/banner-two/cryptik-banner-heart-icon.svg';

import BannerWrapper from "./banner.style"

import subscribe from "../../common"

const BannerOne = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validEmailError, setValidEmailError] = useState(false);
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmailError(false);
    setShowError(false);
    setValidEmailError(false);
    const value = event.target.value;
    setEmail(value);
  }

  const handleSubscribe = () => {
    subscribe({
      email: email,
      subscribed_to_updates: true,
      applied_as_beta_user: false,
      list_id: "c558a7dc-396c-4419-b39e-56a608b63e45"
    })
      .then((response) => {
        setSubscribed(true);
      }).catch(error => {
        const jsonError = JSON.parse(error.request.response)
        if (jsonError.error_first_message === 'To polje ne sme biti prazno.') {
          setEmailError(true);
        }
        else if (jsonError.error_first_message === 'Vnesite veljaven elektronski naslov.') {
          setValidEmailError(true);
        }
        else {
          setShowError(true);
        }
      });
  }

  return (
    <BannerWrapper>
      <Box className="banner-three-wrap">
        <img
          src={particleTopLeft}
          className="section__particle top-left"
          alt="cryptik particles"
        />
        <img
          src={particleUnderLogo}
          className="section__particle two"
          alt="cryptik particles"
        />
        <img
          src={prticleTopRight}
          className="section__particle top-right"
          alt="cryptik particles"
        />
        <img
          src={particleBottomLeft}
          className="section__particle bottom-left"
          alt="cryptik particles"
        />
        <img
          src={particleBottomRight}
          className="section__particle bottom-right"
          alt="cryptik particles"
        />
        <Container>
          <img
            className="banner__thumb"
            alt="cryptik banner thumb"
            src={BannerImage}
          />
          <Row>
            <Col className="lg-8 md-10 xs-12">
              <Box className="banner-content">
                <Heading>
                  {/* The New <br />Work Order */}
                  New Work Order
                </Heading>
                <Text as="span" className="description">
                  Rendering resumes and background checks obsolete.
                </Text>

                <Box className="coin-info">
                  <Box>
                    <FaSearch className="banner-icon" />
                    <Text>Find talent</Text>
                  </Box>
                  <Box>
                    <FaCertificate className="banner-icon" />
                    <Text>Verified skills</Text>
                  </Box>
                  <Box>
                    <FaBezierCurve className="banner-icon" />
                    <Text>Blockchain</Text>
                  </Box>
                </Box>
                <Box className="input-box">
                  {!subscribed ?
                    <>
                      <Input type="email" placeholder="Enter your email" handleEmailChange={(e) => handleEmailChange(e)} />
                      <Button onClick={() => handleSubscribe()}>SUBSCRIBE</Button>
                    </>
                    :
                    <div style={{ 'textAlign': 'center' }}>
                      <span className="successfuly-subscribed">You have successfuly subscribed to newsletter!</span>
                    </div>}
                </Box>
                {emailError && <div className="subscribe-error"><span>Enter email!</span></div>}
                {showError && <div className="subscribe-error"><span>There was an error, please try again!</span></div>}
                {validEmailError && <div className="subscribe-error"><span>Enter a valid email!</span></div>}
              </Box>
            </Col>
          </Row>
        </Container>
      </Box>
    </BannerWrapper >
  )
}

export default BannerOne
